import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  trans,
  useSoyuzExperiment,
  useSoyuzLocales
} from '@spotahome/soyuz/client';
import {
  Colors,
  MediaQuery,
  TrustpilotWidget,
  useCitiesContext
} from '@spotahome/ui-library';
import HistoricalMetricsRedesign from '@spotahome/ui-library/src/tenant/components/HistoricalMetricsRedesign';

import HeroContainer from '@spotahome/landings-common/src/components/HeroContainer';
import HeaderContainer from '@spotahome/landings-common/src/components/HeaderContainer';

import withInViewEvent from '@spotahome/ui-library/src/tenant/components/withInViewEvent';

import { LANDING_SECTIONS } from '@spotahome/ui-library/src/tenant/components/utils/seoConstants';

import classNames from 'classnames';

import CityAutocompleter from './CityAutocompleter';

import './Hero.scss';

const { isMobileScreen, isPhabletScreen, isTabletScreen } = MediaQuery;

const Hero = ({ deviceType }) => {
  const [isDark, setIsDark] = useState(true);
  const { cities } = useCitiesContext();
  useEffect(() => {
    setIsDark(isMobileScreen() || isPhabletScreen() || isTabletScreen());
  }, []);
  const { current: locale } = useSoyuzLocales();
  const MobileTabletHomepageAboveTheFoldExp = useSoyuzExperiment(
    'MobileTabletHomepageAboveTheFold'
  );
  const isMobileTabletHomepageAboveTheFold =
    ['smartphone', 'tablet'].includes(deviceType) &&
    MobileTabletHomepageAboveTheFoldExp.get('behaviour') === 'variant';

  const containerClasses = classNames('hero-tenant__container', {
    'hero-tenant__container--blue-background':
      isMobileTabletHomepageAboveTheFold
  });

  const headerWrapperClasses = classNames('hero-tenant__header-wrapper', {
    'hero-tenant__header-wrapper--blue-background':
      isMobileTabletHomepageAboveTheFold
  });

  const infoClasses = classNames('hero-tenant__info', {
    'hero-tenant__info--blue': isMobileTabletHomepageAboveTheFold
  });

  const titleWrapperClasses = classNames('hero-tenant__info-title', {
    'hero-tenant__info-title--smaller': isMobileTabletHomepageAboveTheFold
  });

  const subtitleWrapperClasses = classNames('hero-tenant__info-subtitle', {
    'hero-tenant__info-subtitle--lighter': isMobileTabletHomepageAboveTheFold
  });

  const historicalMetricsClasses = classNames({
    'hero-tenant__historical-metrics--grey': isMobileTabletHomepageAboveTheFold
  });

  const citySelectorClasses = classNames('hero-tenant__city-selector', {
    'hero-tenant__city-selector--bigger': isMobileTabletHomepageAboveTheFold
  });

  return (
    <section>
      <HeroContainer>
        <div className={headerWrapperClasses}>
          <HeaderContainer
            color={{
              foregroundColor: isDark ? 'dark' : 'light',
              logoColor: isDark ? 'dark' : 'light',
              background: isMobileTabletHomepageAboveTheFold
                ? 'lightBlue'
                : 'transparent'
            }}
            tracking="ga-home-tenant"
            transparent={!isMobileTabletHomepageAboveTheFold}
          />
        </div>
        <div className="hero-tenant__wrapper">
          <div className={containerClasses}>
            <div className={infoClasses}>
              <div className="hero-tenant__info-content">
                <h1 className={titleWrapperClasses}>
                  {trans('home.tenant.hero.title.experiment')}
                </h1>
                <h2 className={subtitleWrapperClasses}>
                  {trans('home.tenant.hero.subtitle.experiment')}
                </h2>
                <CityAutocompleter
                  cities={cities}
                  deviceType={deviceType}
                  className={citySelectorClasses}
                  iconColor={Colors.medium}
                  isMobileTabletHomepageAboveTheFold={
                    isMobileTabletHomepageAboveTheFold
                  }
                />
                {isMobileTabletHomepageAboveTheFold && (
                  <TrustpilotWidget
                    locale={locale}
                    className="hero-tenant__trustpilot-wrapper-redesign"
                    template={
                      isMobileTabletHomepageAboveTheFold
                        ? 'microTrustScore'
                        : 'mini'
                    }
                  />
                )}
              </div>
            </div>
            <div className="hero-tenant__container-bottom">
              {!isMobileTabletHomepageAboveTheFold && (
                <TrustpilotWidget
                  locale={locale}
                  className="hero-tenant__trustpilot-wrapper"
                  template={
                    isMobileTabletHomepageAboveTheFold
                      ? 'microTrustScore'
                      : 'mini'
                  }
                />
              )}
            </div>
          </div>
        </div>
      </HeroContainer>
      <HistoricalMetricsRedesign className={historicalMetricsClasses} />
    </section>
  );
};

Hero.propTypes = {
  deviceType: PropTypes.oneOf(['smartphone', 'tablet', 'desktop']).isRequired
};

export default withInViewEvent(LANDING_SECTIONS.hero)(Hero);
